import React, { useEffect } from "react";
import MyAppBar from "../src/components/MyAppBar";
import { Box, Container, Paper } from "@material-ui/core";
import ROUTES from "../routes";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { selectUser } from "../src/redux/auth/auth.selectors";
import SignIn from "../src/components/SignIn.component";
import PageWrapper from "../src/components/PageWrapper";

const SignInScreen = () => {
  const router = useRouter();
  const { redirect, as } = router.query;
  const user = useSelector(selectUser);

  useEffect(() => {
    if (user) {
      if (redirect && as) {
        router.push(`${redirect}?email=${user.email}`, `${as}`);
      }
      if (redirect) {
        router.push(`${redirect}?email=${user.email}`);
      } else {
        router.push(`${ROUTES.HOME}`);
      }
    }
  }, [user]);

  return (
    <PageWrapper>
      <MyAppBar />
      <Container maxWidth="md">
        <Box marginY={4}>
          <Paper elevation={2} style={{ marginTop: "1rem" }}>
            <SignIn />
          </Paper>
        </Box>
      </Container>
    </PageWrapper>
  );
};

export default SignInScreen;
